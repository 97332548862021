import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import axios from "axios";


import Layout from '../components/layout'
import Image from '../components/image'

import Preview from '../components/form-preview.js'
import logo from "../images/paintly-logo.svg"


const PREVIEW_KEY = String(process.env.PREVIEW_KEY);
class IndexPage extends React.Component {
  constructor(){
    super();
    this.fileInput = React.createRef();
    this.state = {
      email: "",
      file: "",
      filePath: {},
      fileObject: {},
      signedUrl: "",
      fileType: "",
      imagePreview: null,
      isLoading: false,
     
      
    };

    this.upload = this.upload.bind(this);
    this.signed = this.signed.bind(this);
  }

  
  //User Selecting their image send to S3 bucket.

  upload = event =>{
    
    const filesObject = event.target.files;
    if (filesObject && filesObject.length > 0) {
      const fileObject = filesObject[0];
      this.setState({ fileObject });
    }
   
     console.log(event.target.files[0]);
    //1 Copy state:
    const file = [ ...this.state.file ];
    //2 Add our file to the var file
    file[0] = event.target.files[0].name;
    //3 Set state
    this.setState({ file: file });

    //1 Copy state:
    const filePath = { ...this.state.filePath };
    //2 Add our file to the var file
    filePath[0] = URL.createObjectURL(event.target.files[0]);
    //3 Set state
    this.setState({ filePath: filePath });

    //Set filetype
    this.setState({ fileType: event.target.files[0].type });

  }

  signed = event =>{
    let Preview = this;
    const { fileObject } = this.state;
    let baseS3Url = "https://j70syjkosl.execute-api.us-east-1.amazonaws.com/prod/get-s3-url?object-name=";
    let imgUrl = `${baseS3Url}${this.state.file[0]}&content-type=${this.state.fileType}`;

    const options = {
      params: {
        Key: this.state.file[0],
        ContentType: this.state.fileType
      },
      headers: {
        'Content-Type': this.state.fileType,
        'x-api-key' : PREVIEW_KEY
      }
    };

    event.preventDefault();

    //set loading to true for loading message 
    Preview.setState({isLoading:true});
    
    // 1. make a get to lambda function for signed url
    axios.get(imgUrl, options)
    .then(function (result) {
      
      const signedUrl = result.data;
// 1. Then use result to put img in S3 with signed URL
      axios.put(signedUrl, fileObject, options)
      .then(function (result) {
        console.log("you did it bitch!");
        
   
        Preview.submitForPreview(event);
      })
      .catch(function (err) {
        console.log(err);
      });
    })
  }

  submitForPreview = event =>{

    let Preview = this;
    let getPreviewUrlBase = 'https://j70syjkosl.execute-api.us-east-1.amazonaws.com/prod/quick-pbn?s3-path=s3://oasis-input/';
    let getPreviewUrl = `${getPreviewUrlBase}${Preview.state.file[0]}`;
 
   
 

   
  
    

  
      axios({
        method: 'get',
        url: getPreviewUrl,
        headers: {'x-api-key' : PREVIEW_KEY},
        responseType: 'arraybuffer'
      })
        .then(function (response) {
  
          var blob = new Blob( [ response.data ], { type: Preview.state.fileType } );
          var imageUrl = URL.createObjectURL( blob ); 
          
          Preview.setState({imagePreview: imageUrl});

          Preview.setState({isLoading: false});
        });
   
  }

  
  

  render() {
    return (
      <Layout>
    <div className="hero block items-center md:pl-32 md:pr-32 ">
    <h1 className="pb-20 pt-20 font-h1 u-text-red text-5xl">Discover Creativity & Relaxation</h1>
      <div className="md:flex">
        <div className="flex-1 pt-10 pr-10">
          <h2 className="pb-10 text-3xl font-h2 text-blue">Make a paint by numbers kit <br></br>of your favorite photo</h2>
          
          <Preview upload ={this.upload} signed = {this.signed} loadingClick = {this.loadingClick} filePath = {this.state.filePath}  imagePreview= {this.state.imagePreview} fileInput={this.state.fileInput} isLoading={this.state.isLoading}/>

        </div>
        <div className="flex-1">
            <Img fluid={this.props.data.imageTwo.childImageSharp.fluid} />
        </div>
      </div>
      {/* <div className="-mt-20 pb-4">
        <div className="img-dog">
          <Img fluid={this.props.data.imageThree.childImageSharp.fluid} />
        </div>
      </div> */}
    </div>
   

{/* 
   // TO DO LITTLE SECTION SHOWING THE VILLIAN LIFE BEING HECTIC MESSAGES POPING UP
        Life is hecktic
        Find Balance


      NEXT SECTION: Make your Kit

       <div>
        <div>
          <h2>
            Make Your Paint By Numbers Kit
          </h2>
          Steps for hero to follow 1-4
        </div>
      </div>

       <Link to="/page-2/">Go to page 2</Link>
*/}  
 
   
{/* 
Multi
line comment
    Different ways to do images:  
  <Img fluid={props.data.imageOne.childImageSharp.fluid} />
  <Image />
*/} 

  <div className="footer pt-20 flex justify-center">
    <img src={logo} alt="Paintly" className="logo pl-10 text-center"/>
    
  </div>
  
  <div className="flex justify-end bg-gray-200 copyright">
    <div className="text-gray-700 text-center px-4 py-2 m-2">
      <span>© Paintly 2019 All Rights Reserved</span>
    </div>
  </div>
  </Layout>
    )
  }
}
  



export default IndexPage


export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "placeholder.png" }) {
      ...fluidImage
    }
    imageTwo: file(relativePath: { eq: "painting-relaxation@2x.png" }) {
      ...fluidImage
    }
    imageThree: file(relativePath: { eq: "iphone-dog@2x.png" }) {
      ...fluidImage
    }
    imageFour: file(relativePath: { eq: "paintly-logo@2x.png" }) {
      ...fluidImage
    }
  }
`

