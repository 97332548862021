import React from 'react'
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';




class Preview extends React.Component {

 


    render() {
      const isLoadingIcon = this.props.isLoading;
      let loadingIcon;
      if (isLoadingIcon) {
        loadingIcon = <Spinner as="span" animation="border"  />;
      }

      return (
        <div>
          <form name="user-input" method="POST" className="preview" onSubmit={this.props.signed}>
            <input type="file" ref={this.fileInput} onChange={this.props.upload}/>
            {/* <button className="btn btn-blue ml-auto mr-10" type="submit">See my art by numbers!</button> */}
            <Button variant="primary" type="submit" disabled={this.props.isLoading} onClick={!this.props.isLoading ? this.props.loadingClick : null} >
              {loadingIcon}
              {this.props.isLoading ? 'Loading…' : 'Click to load'}
              
            </Button>{' '}
          </form>
          <img src={this.props.filePath[0]}/>
          <div>
            <img src={this.props.imagePreview}/>
          </div>
        </div>
            
      )
    }
  }
  

export default Preview